<template>
<vuestic-widget>
  <template slot="header">
    <div class="w-100 d-flex justify-content-between align-items-center">
      <span class="title">Automation share</span>
    </div>
  </template>
  <div class="row">
    <div class="col-md-12">
      <div class="d-flex justify-content-center" v-if="loadingAutomation">
        <span class="atom-spinner-wrapper">
          <atom-spinner slot="loading" :animation-duration="1500" :size="120" color="rgb(53,120,198)"/>
        </span>
      </div>
      <div v-else-if="automation" class="box-share">
        <div class="icon">
          <i class="fa fa-joomla"></i>
        </div>
        <div class="name">
            {{ automation.name }}
        </div>
        <div class="action">
            <button class="btn btn-primary" :disabled="loadingDuplicate" @click="duplicate">
              <atom-spinner v-if="loadingDuplicate" slot="loading" :animation-duration="1500" :size="14" color="#FFF"/>
              <span v-else>Add To Automations</span>
            </button>
            <button class="btn btn-dark ml-2" @click="forget">
              Forget
            </button>
        </div>
      </div>
    </div>
  </div>
</vuestic-widget>
</template>

<script>
export default {
  components: {
  },

  data () {
    return {
      loadingAutomation: false,
      automation: null,
      loadingDuplicate: false,
    }
  },

  mounted () {
    this.getAutomation()
  },

  methods: {
    getAutomation () {
      this.loadingAutomation = true
      this.$store
        .dispatch('automation/findByUniqueId', this.$route.params.token)
        .then((automation) => {
          this.loadingAutomation = false
          this.automation = automation
        })
        .catch(() => {
          this.$router.push({ name: 'business.automation.index' })
          this.loadingAutomation = false
        })
    },

    forget () {
      this.$router.push({ name: 'business.automation.index' })
    },

    duplicate () {
      this.loadingDuplicate = true
      this.$store
        .dispatch('automation/duplicate', this.$route.params.token)
        .then((automation) => {
          this.loadingDuplicate = false
          this.$router.push({ name: 'business.automation.edit', params: { id: automation.id } })
        })
        .catch(() => {
          this.loadingDuplicate = false
        })
    }
  },
}
</script>

<style lang="scss" scoped>
.box-share {
    text-align: center;
    padding: 50px 0px;
    .icon {
      display: inline-block;
      background: #ff694c;
      color: #fff;
      border-radius: 50%;
      padding: 26px 35px;
      .fa {
        font-size: 120px;;
      }
    }
    .name {
      color: #394a55;
      font-weight: bold;
      font-size: 24px;
      margin: 20px 0px;
    }
}

</style>